
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Roboto:wght@100;300&display=swap');

.Wrapper {
margin-left: 5px;

.tmcontainer {
display: flex;
align-items: center;
font-family: 'Roboto', sans-serif, "Montserrat", monospace; 
width: 100%;
        

        .time{
            border:green;
            width:220px ;
        }
    }
}