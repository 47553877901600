
:root{
  --dark: #3B3B40;
  --white: #ffffff;
  
}

body {
  width: 100%;
  height: 100vh;
  margin: 0;
}

.App{
  box-sizing: border-box;
  background-color: var(--white);
  color:var(--dark);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap:7px;

}

.App  button{
  position: absolute;
  right: 0;
  width: 100px;
  border-radius: 30px;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;


}

.darkApp{
  box-sizing: border-box;
  background-color: var(--dark);
  color:var(--white);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.darkApp  button{
  position: absolute;
  right: 0;
  width: 100px;
  border-radius: 30px;
  outline: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.5);
  background-color:#3B3B40;
  color: #ffffff;

}
